@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .h-inherit {
        height: inherit;
    }

    .font-gilroy {
        font-family: 'Gilroy', sans-serif;
    }
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../static/fonts/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    /* Light */
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../static/fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    /* Regular */
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../static/fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    /* Medium */
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../static/fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: 700;
    /* Bold */
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../static/fonts/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    /* Extra Bold */
    font-style: normal;
}

html {
    margin: 0;
    padding: 0;
    background-color: '#ECECEC';
    height: 100%;
    font-family: 'Gilroy', -apple-system, Roboto, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #ECECEC;
}

#root {
    height: 100%;
}

* {
    scroll-behavior: smooth;
    font-family: 'Gilroy', -apple-system, Roboto, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.mn-cont {
    background-color: #ECECEC;
    transition: all 3ms linear;
    overflow-y: auto;
}

.block {
    display: block;
}

.tableRow:nth-child(1n)>* {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.sl-table tbody {
    display: block;
    overflow-y: scroll;
    height: 100%;
}

.sl-table thead,
.sl-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.sl-table tbody::-webkit-scrollbar {
    display: block;
}

::-webkit-scrollbar,
::-webkit-scrollbar:horizontal {
    width: 6px;
    height: 6px;

}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track:horizontal {
    background: transparent;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
    background: #C9D2CE;
    border-radius: 5px;
}

input[type="password"] {
	font-family: "Gilroy";
	font-style: normal;
	font-weight: normal;
	speak: none;

	font-variant: normal;
	text-transform: none;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	letter-spacing: 2px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    /* Transition is used to override autofill color over time */
    -webkit-text-fill-color: #000 !important;
    /* Ensures the text color is black */
    background-color: inherit !important;
    /* Your desired background color */
}


.z-index-1 {
    z-index: 1;
}

.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

.progress-cursor {
    cursor: progress;
}

.form-inp_pl {
    outline: none;
}

.striped tr:nth-child(even) {
    background-color: #F9F9F9 !important;
}

.striped-hv tr:hover {
    background-color: #F7F7F7 !important;
}

#inactive-stripped {
    background-color: #EDEDEE !important;
}

#checked-stripped {
    background-color: #D6F2E5 !important;
}

textarea::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #969F9B;
}

.dng-pl::placeholder {
    color: #F33B5C;
}

.sticky {
    position: fixed;
    top: 0;
    /*width: calc(91.1% - 9px);*/
    z-index: 999
}

.highlight {
    color: #F7F7F7;
}

.highlight-bg {
    background-color: #FFDE78 !important;
}

.no_dot_list {
    list-style: none
}

.no_underline {
    text-decoration: none
}

/* UNSETS */

h3 {
    margin: 0;
    padding: 0;
    /*list-style: ;*/
    all: unset;
}

p {
    all: unset;
    display: block;
}

ul {
    all: unset;
}

/* ====== */

.sr-form {
    background: #FFFFFF;
    border: 1px solid #C9D2CE;
    height: 32px;
    box-sizing: border-box;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    color: #181A19;
    width: 11.25rem;
    transition: 0.3s linear;
    transition-property: width, padding;
}

.sr-form:hover {
    border: 1px solid #C9D2CE;
    box-shadow: 0 2px 4px rgba(168, 168, 168, 0.15);
}

.initial-condition {
    border: 1px solid #EDEDEE;
    padding: 7px;
    width: 2rem;
    transition: 0.3s linear;
    transition-property: width, padding;
}

.sr-inp {
    width: calc(100% - 42px);
    border: none;
    outline: none;
    background-color: transparent
}

.initial-condition .sr-mag {
    margin-right: 0 !important;
}

.step-block {
    height: 500px;
    width: 400px;
    background-color: #ccc;
}

.active {
    color: #f00
}

.initial-condition .sr-mag path {
    stroke: #6D7471 !important
}

.text-no-wrap {
    white-space: nowrap
}

.initial-condition .sr-inp {
    display: none;
}

.initial-condition .x-circle {
    display: none;
}

.sr-mag {
    margin-right: 6px;
    display: block;
    transition: all 0.3s linear;
}

.x-circle {
    display: block;
    transition: all 0.3s linear;
}

.not-visible {
    display: none;
}

.opacity-0 {
    display: none;
}

.arr-r {
    transform: rotateZ(180deg);
    transition: all 0.2s linear;
}

.arr-l {
    transition: all 0.2s linear;
}

.error-msg {
    background: #D90714;
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
}

.error-msg #notistack-snackbar {
    width: 100%;
    padding: 0;
}

.success-msg {
    background: #19C215;
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
}

.notistack-MuiContent {
    padding:0;
    overflow: hidden;
}

.success-msg #notistack-snackbar {
    width: 100%;
    padding: 0;
}

.info-msg {
    background: #ffb703;
}

.info-msg #notistack-snackbar {
    width: 100%;
    padding: 0;
}

.tag_dots {
    display: none;
}

.stc-tag:hover .tag_dots {
    display: flex !important
}

/* hide arrows: Firefox */
.num-inp[type=number] {
    -moz-appearance: textfield;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    margin: 0;

}

.form-inp_pl::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #969F9B;
    font-family: Roboto, sans-serif;
    letter-spacing: 0;
}

.checkbox {
    border: 1px solid #E8E8E8;
}

.checkbox input[type="checkbox"] {
    background: #FFFFFF;
}

.checkbox input:checked {
    background: red;
}

input[type="radio"] {
    color: red;
    border-color: red;
}

input[type="radio"]:checked {
    color: red;
    border-color: red;
}

.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
}

.rdw-option-wrapper:hover {
    box-shadow: 0 2px 4px 0 rgba(130, 130, 130, 0.05) !important;
}

@media screen and (max-width: 1024px) {
    .slick-slide {
        margin-right: 12px;
    }

    .slick-current {
        margin-left: 16px;
    }
}
