.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #e80202;
    border-radius: 32px;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
    background: #FAFAFA;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), inset 0 2px 2px #FFFFFF, inset 0 -1px 1px rgba(0, 0, 0, 0.1);
}

.switchable:checked+.slider {
    background-color: #0FCF68;
}

/*noinspection CssUnknownProperty*/
.switchable:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}
