.flex {
  display: flex;
}

.flex-cent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: start;
}

.items-end {
  align-items: end;
}

.justify-start {
  justify-content: start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.gap-1 {
  /* 4px */
  gap: 0.25rem;
}

.gap-1_5 {
  /* 5px */
  gap: 0.313rem;
}

.gap-2 {
  /* 8px */
  gap: 0.5rem;
}

.gap-2_5 {
  /* 10px */
  gap: 0.625rem;
}

.gap-3 {
  /* 12px */
  gap: 0.75rem;
}

.gap-4 {
  /* 14px */
  gap: 0.875rem;
}

.gap-4_5 {
  /* 15px */
  gap: 0.938rem;
}

.gap-5 {
  /* 16px */
  gap: 1rem;
}

.gap-6 {
  /* 18px */
  gap: 1.125rem;
}

.gap-6_5 {
  /* 20px */
  gap: 1.25rem;
}

.gap-7 {
  /* 22px */
  gap: 1.375rem;
}

.gap-7_5 {
  /* 24px */
  gap: 1.5rem;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.dis_sl {
  user-select: none;
  /* standard */
  -moz-user-select: none;
  /* firefox specific */
  -webkit-user-select: none;
  /* Chrome, Opera and Safari*/
  -ms-user-select: none;
  /* IE, ms-edge */
}

.stable-scroll {
  scrollbar-gutter: stable;
}

.btn-smp {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: #181a19;
  font-weight: 500;
  font-size: 13px;
  justify-content: center;
}

.is-inline-block {
  display: inline-block;
}

.overflowY-auto {
  overflow-y: auto;
}

.overflowY-scroll {
  overflow-y: scroll;
}

.dis_scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
}

.z-index-1 {
  z-index: 1;
}

.not-allowed {
  cursor: not-allowed;
}

.progress-cursor {
  cursor: progress;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.font-700 {
  font-weight: 700;
}

.font-600 {
  font-weight: 600;
}

.font-500 {
  font-weight: 500;
}

.font-400 {
  font-weight: 400;
}

.rounded {
  border-radius: 4px;
}

.rounded-s {
  border-radius: 8px;
}

.rounded-m {
  border-radius: 10px;
}

.rounded-full {
  border-radius: 32px;
}

.overflow-hidden {
  overflow: hidden;
}

.text-xs {
  font-size: 12px;
}

.text-s {
  font-size: 14px;
}

.text-m {
  font-size: 16px;
}

.text-l {
  font-size: 18px;
}

.text-xl {
  font-size: 20px;
}

.text-black {
  color: #000;
}

.text-white {
  color: #fff;
}

.text-red {
  color: #d90714;
}

.text-lightRed {
  color: #e7010f;
}

.text-blue {
  color: #305680;
}

.text-placeholder {
  color: #7c7d7e;
}

.text-disabled {
  color: #afafaf;
}

.decoration-none {
  text-decoration: none;
}

.text-def {
  color: #8d8e90;
}

.marginAuto {
  margin: 0 auto;
}

.bgBlack {
  background-color: #000000;
}

.textHover::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 1px;
  background: #ffffff;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.textHover:hover::before {
  width: 100%;
}
