.canvas-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.drawing-cursor {
  cursor: crosshair !important;
}

.default-cursor {
  cursor: default !important;
}
