:root {
  --rdp-cell-size: 32px !important;
  --rdp-outline: 0px !important;
  --rdp-outline-selected: 0px !important;
  --rdp-accent-color: none !important;
  --rdp-background-color: none !important;
  --rdp-accent-color-dark: none !important;
  --rdp-background-color-dark: none !important;
}

.rdp {
  margin: 0 !important;
}

.rdp-month {
  width: 100% !important;
}

.rdp-nav_button {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
}

.rdp-head {
  height: 32px;
  padding: 0 !important;
}

.rdp-table {
  border-collapse: separate !important;
  border-spacing: 2px;
  width: 100% !important;
  max-width: 100% !important;
}

.rdp-head_cell {
  font-size: 15px !important;
  font-weight: 300 !important;
  color: #1d1e1f !important;
}

.rdp-day_selected:focus-visible {
  outline: none !important;
  outline-offset: 0 !important;
}

.rdp-button:focus-visible:not([disabled]) {
  background-color: transparent !important;
  border: 2px solid transparent !important;
}

.rdp-day {
  display: inline-block !important;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.rdp-button:hover:not([disabled]):not([aria-selected="true"]) {
  background-color: rgba(48, 86, 128, 0.1) !important;
  border-radius: unset;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  background-color: rgba(48, 86, 128, 0.1) !important;
}
